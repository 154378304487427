.tile-title {
  padding: 16px;
}

.tile-container {
  display: flex;
  gap: 16px;
  overflow-x: auto;
  padding-bottom: 24px;
  padding-right: 8px;
  padding-right: 16px;
  padding-left: 16px;
}

.tile-box {
  background-color: var(--white);
  /* border-radius: 8px; */
  padding: 12px;
  width: 120px;
  min-width: 120px;
  height: 140px;
  overflow: hidden;
  position: relative;
  display: flex;
  cursor: pointer;
}

.tile-box:hover {
  background-color: var(--light-grey);
}

.tile-image {
  position: absolute;
  height: 100%;
  top: -23px;
  right: -80%;
}

.tile-content {
  position: relative;
  margin-top: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.tile-header {
  color: var(--primary-text-color);
  font-size: 13px;
  margin: 0px;
}

.tile-text {
  color: var(--secondary-text-color);
  font-size: 9px;
  margin: 0px;
}

.tile-button {
  color: var(--primary-text-color);
  position: absolute;
  top: -2px;
  right: 0;
}

@media (min-width: 768px) {
  .tile-container {
    justify-content: center;
  }

  /* .tile-box {
    width: 240px;
    min-width: 240px;
    height: 280px;
    flex: 1;
  } */
}