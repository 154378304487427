.hero-input-container {
  display: flex;
  gap: 16px;
  position: relative;
  margin: 16px 0px;
  max-width: 400px;
  width: 100%;
  min-width: 100%;
}

.hero-input-icon {
  position: absolute;
  top: 10px;
  left: 8px;
  color: var(--light-grey);
}

.hero-search-input {
  flex: 1;
  width: 100%;
  height: 36px;
  /* border-radius: 4px; */
  border: 0px;
  padding-left: 32px;
  color: var(--primary-text-color);
  font-size: 14px;
  letter-spacing: 0.3px;
  font-weight: 300;
}

.input-button {
  min-width: 42px;
  width: 42px;
  /* border-radius: 4px; */
  border: 0px;
  background-color: var(--white);
  color: var(--primary-text-color);
  cursor: pointer;
}