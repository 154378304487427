.hero-container {
  padding: 64px 16px;
  color: var(--white);
  background-image: url('/public/img/vehicles/black-car.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.blurred-bg {
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  opacity: 0.5;
}

.hero-content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 60%;
  height: auto;
}

.hero-content h1,
.hero-content h2,
.hero-content h3,
.hero-content h4,
.hero-content h5,
.hero-content p {
  margin: 8px 0px;
  color: var(--white);
}

.name-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon-counter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

@media (min-width: 768px) {
  .hero-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
