.details-container {
  display: flex;
  gap: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
  flex-wrap: wrap;
}

.details-content {
  background-color: var(--white);
  padding: 16px;
  flex: 1;
  min-width: 280px;
}

.details-car {
  padding: 0px;
  position: relative;
  flex: 2;
}

.details-car-content {
  padding: 16px;
}

.details-car .price {
  color: var(--primary-text-color);
  font-weight: 900;
  font-size: 24px;
}

.details-car img {
  max-width: 100%;
  min-width: 100%
}

.details-shop {}

.details-shop h2 {
  margin-top: 0px;
}

.shop-details-social {
  display: flex;
  gap: 16px;
  margin: 16px 0px;
  font-size: 32px;
  cursor: pointer;
  color: var(--secondary-text-color);
}

.shop-details-social svg:hover {
  color: var(--primary-text-color);
}

.terms-content {
  margin-bottom: 15px;
  margin-top: 15px;
}

.terms-content p {
  margin-top: 15px;
  font-size: 11px;
  color: var(--secondary-text-color);
  margin: 0px;
  padding: 0px;
}

.whatsapp-btn {
  background-color: #25d366;
  color: white;
}

.whatsapp-btn :hover {}

.details-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btn-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  border: 0px;
  background-color: var(--primary-color);
  color: var(--primary-text-color);
}

.btn-icon-detalhes-heart {}

.details-heart {
  position: absolute;
  top: 8px;
  right: 8px;
}

.details-back {
  position: absolute;
  top: 8px;
  left: 8px;
}