.explore-wrapper {
  background: var(--white);
  padding: 64px 0px;
  margin: 64px 0px;
}

.explore-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 64px;
  flex-wrap: wrap;
  /* border-radius: 8px; */
}

.explore-image {
  flex: 1;
  display: flex;
  gap: 16px;
  min-width: 200px;
}

.image-one {
  object-fit: cover;
  max-width: 100%;
  height: 276px;
  /* border-radius: 8px; */
  overflow: hidden;
  filter: grayscale(70%);
}

.image-two {
  object-fit: cover;
  max-width: 100%;
  height: 130px;
  /* border-radius: 8px; */
  overflow: hidden;
  filter: grayscale(70%);
}

.image-layer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.explore-content {
  flex: 1;
  line-height: 26px;
  min-width: 200px;
}

.explore-content h2 {
  margin-top: 0px;
}