.favorites-container {
    margin: 16px 0px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.counter {
    position: sticky;
    bottom: 0px;
    left: 0;
    background-color: var(--white);
    color: var(--secondary-text-color);
    width: 100%;
    padding: 4px;
    font-size: 13px;
    margin-top: auto;
}

.vermais-container {
    margin: 16px 0px;
}

@media (min-width: 768px) {
    .favorites-container {
        flex-direction: row;
        flex-wrap: wrap;
    }
}