.veiculos-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0px;
  padding-top: 0px;
  flex-wrap: wrap;
}

@media (min-width: 768px) {
  .veiculos-container {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 32px;
  }
}

.oops {
  color: var(--secondary-text-color);
  padding: 32px;
}
