.bullet-list {
  color: var(--secondary-text-color);
  margin-top: 64px;
  margin-bottom: 64px;
  text-align: center;

  /* display: flex; */
}

.benefits-list {
  margin-top: 32px;
  color: var(--primary-text-color);
  list-style-type: none;
  /* margin-left: 0px; */
  padding-left: 0px;
  gap: 16px;
  display: flex;
  flex-wrap: wrap;
}
.bullet-icon {
  color: var(--primary-color);
  font-size: 96px;
}
.benefits-list li {
  min-width: 200px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 8px;
  background-color: var(--white);
  padding: 16px;
}

.benefits-list li h4,
.benefits-list li p {
  margin: 0px;
}
