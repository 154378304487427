header {
  background-color: black;
  color: var(--white);
  padding: 16px 0px;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 99;
  position: sticky;
}

header ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 24px;
  font-size: 14px;
  position: relative;
}

header ul li img {
  height: 32px;
  margin-bottom: -8px;
}

header ul li {
  display: flex;
  align-items: center;
  justify-content: center;
}

header ul li a {
  color: white;
  text-decoration: none;
}

.nav-icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  font-size: 22px;
}

.nav-desktop {
  display: none;
}

.nav-mobile.active a,
.nav-desktop.active a {
  color: var(--primary-color);
}

@media (min-width: 768px) {
  header ul {
    flex-direction: row;
    justify-content: center;
    gap: 32px;
  }

  .nav-icon {
    display: none;
  }

  .nav-mobile {
    display: none;
  }

  .nav-desktop {
    display: flex;
  }
}
