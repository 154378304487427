/* src/pages/ContactPage.css */
.contact-page {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

.contact-page h1 {
    font-size: 2em;
    margin-bottom: 10px;
}

.contact-page p {
    margin-bottom: 20px;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.contact-form label {
    font-weight: bold;
}

.contact-form input,
.contact-form textarea {
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ccc;
    /* border-radius: 4px; */
}

.contact-form button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    /* border-radius: 4px; */
    font-size: 1em;
    cursor: pointer;
}

.contact-form button:hover {
    background-color: #0056b3;
}

.contact-info {
    margin-top: 20px;
}

.contact-info h2 {
    font-size: 1.5em;
}

.contact-info a {
    color: #007bff;
    text-decoration: none;
}

.contact-info a:hover {
    text-decoration: underline;
}