:root {
  --primary-color: #e7d000;
  --secondary-color: #fae421;
  --accent-color: #e74c3c;
  --background-color: #ececec;
  --primary-text-color: #424242;
  --secondary-text-color: #8d8d8d;
  --light-grey: rgb(192, 192, 192);
  --white: white;
  --mobile-viewport: 768px;
}

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

body {
  font-family: 'Roboto', sans-serif;
  margin: 0px;
  background: var(--background-color);
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 16px 0px;
  color: var(--primary-text-color);
}

h6 {
  letter-spacing: 0.5px;
}

.color-white {
  color: var(--white);
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0px;
  padding: 8px 24px;
  /* border-radius: 8px; */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  font-weight: 600;
}

.button svg {
  font-size: 18px;
}

.button:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.button-big {
  font-size: 16px;
  padding: 12px 32px;
}

.button-primary {
  background-color: var(--primary-color);
  color: var(--primary-text-color);
}

.button-primary:hover {
  background-color: var(--secondary-color);
}

.button-secondary {
  background-color: var(--secondary-text-color);
  color: var(--white);
}

.button-secondary:hover {
  background-color: var(--light-grey);
}

.container-fluid {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}