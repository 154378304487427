.card {
  width: 100%;
  height: 100px;
  /* border-radius: 8px; */
  display: flex;
  /* gap: 16px; */
  /* padding: 8px; */
  background: var(--white);
  position: relative;
  cursor: pointer;
  transition: all .2s ease;
}

.card:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}

.icon-heart {
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  right: 50px;
  width: 20px;
  height: 20px;
  /* border-radius: 30px; */
}

.icon-disabled {
  color: var(--background-color);
}

.icon-enabled {
  color: var(--primary-color);
}

.card-image {
  object-fit: cover;
  height: 100%;
  width: 35%;
  min-width: 35%;
  /* border-radius: 8px; */
}

.card-content {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow: hidden;
  justify-content: space-around;
  padding: 8px;
  /* max-width: 50%; */
}

.card-name {
  margin: 0px;
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.card-year {
  margin: 0px;
  font-size: 10px;
  color: var(--secondary-text-color);
  letter-spacing: 0.3px;
  font-weight: 300;
}

.card-location {
  margin: 0px;
  font-size: 10px;
  color: var(--secondary-text-color);
  font-weight: 300;
}

.icon-container {
  display: flex;
  gap: 8px;
}

.card-icon {
  color: var(--secondary-text-color);
}

.card-price {
  margin: 0;
  font-size: 14px;
  /* color: var(--primary-color); */
  color: var(--primary-text-color);
  font-weight: bold;
}

.card-button {
  position: absolute;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;

  bottom: 8px;
  right: 8px;
  border: 0px;
  background: var(--primary-color);
  color: var(--white);
  padding: 4px 2px;
  border-radius: 100%;
  font-size: 12px;
  font-weight: 600;
  margin-top: auto;
}

.card-button svg {
  font-size: 16px;
}

.card-button:hover {
  cursor: pointer;
  /* background: var(--background-color); */
}

@media (min-width: 768px) {
  .card {
    height: 360px;
    flex-direction: column;
    /* gap: 8px; */
    min-width: 240px;
    max-width: 320px;
    flex: 1;
  }

  .card-image {
    height: 240px;
    width: 100%;
  }

  .card-price {
    font-size: 18px;
  }
}