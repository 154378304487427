.tiletwo-container {
  display: flex;
  /* flex-wrap: wrap; */
  gap: 16px;
  overflow-x: auto;
}

.tiletwo-box {
  /* padding: 16px; */
  flex: 1;
  min-width: 140px;
  max-width: 260px;
  height: 300px;
  cursor: pointer;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  filter: grayscale(70%);
  display: flex;
  flex-direction: column;
  position: relative;
  transition: filter 0.2s ease-in;
  /* align-items: flex-end;
    justify-content: flex-end; */
}

.tiletwo-box:hover {
  filter: grayscale(0%);
}

.tiletwo-box:hover .tiletwo-button {
  background-color: var(--primary-color);
  transform: rotate(45deg);
  /* Altere para a cor de fundo desejada no hover */
}

.tiletwo-box img {
  height: 100%;
}

.tiletwo-button {
  position: absolute;
  bottom: 16px;
  right: 16px;
  border: 0px;
  background-color: var(--white);
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  transition: transform 0.2s ease-in;
  /* box-shadow: 1px 1px 4px 0px black; */
  cursor: pointer;
}

.tiletwo-button svg {
  font-size: 22px;
}

.tiletwo-content {
  padding: 16px;
  background-color: rgba(0, 0, 0, 0.3);
  /* background-color: red; */
}

.tiletwo-box h4,
.tiletwo-box p {
  /* color: wheat; */
  /* font-weight: 300; */
  margin: 0px;
  color: white;
  /* font-weight: 900; */
  text-shadow: 1px 1px 0px black;
}

.tiletwo-img-one {
  background-image: url('/public/img/vehicles/civic-back.png');
}

.tiletwo-img-two {
  background-image: url('/public/img/vehicles/corolla-2.png');
}

.tiletwo-img-three {
  background-image: url('/public/img/vehicles/hilux-back.png');
}

.tiletwo-img-five {
  background-image: url('/public/img/vehicles/compass-front-2.png');
}