footer {
  color: var(--white);
  margin-top: auto;
  color: var(--white);
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
}

footer img {
  /* margin-top: 64px; */
  margin-top: 64px;
  max-width: 300px;
}

footer h5,
footer h6 {
  margin: 12px 0px;
  color: var(--white);
  opacity: 0.2;
}

footer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('/public/img/vehicles/bg-black.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transform: scaleX(-1);
  z-index: -2;
}

footer::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  opacity: 0.3;
  z-index: -1;
}

footer ul {
  margin: 0px;
  padding: 0px 16px;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: 14px;
}

.footer-link {
  color: var(--secondary-text-color);
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
}

@media (min-width: 768px) {
  footer ul {
    flex-direction: row;
    justify-content: center;
    gap: 32px;
  }
}
